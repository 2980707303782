import React from "react";
import { navigate } from "gatsby"; //import navigate from gatsby
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Crypt from "../utils/crypt";

export default class Redirect extends React.Component {
  render() {
    var canUseDOM = !!(
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    );

    if (typeof trackCustomEvent === "function") {
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: this.props.pageContext.frontmatter.name,
        // string - required - Type of interaction (e.g. 'play')
        action: "visit",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: "website",
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: this.props.pageContext.frontmatter.level
      });
    }

    if (typeof window !== "undefined" && window.localStorage) {
      // DONE - encode to prevent level forgery
      let crypt = new Crypt();
      localStorage.setItem(
        "name",
        crypt.encrypt( this.props.pageContext.frontmatter.name )
      );
      localStorage.setItem(
        "level",
        crypt.encrypt(''+this.props.pageContext.frontmatter.level)
      );
    }

    // console.log("im redirect", this.props.pageContext);
    if (canUseDOM) {
      navigate(this.props.pageContext.target);
    }
    return (
      <meta http-equiv="refresh" content="1; URL=/" /> && <p>Please wait...</p>
    );
  }
}
